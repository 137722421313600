.myInput{
	font-family: 'Balboa';
	font-size: 3.5vh;
	color: #fff;
}

.myInput input{
	font-family: "Balboa";
	/*margin-top: 5px; */
	background-color: black;
	font-size: 3.5vh;
	border-radius: 5px;
	border: 1px solid white;
	color:#fff;
	padding:10px;	
}

.myInputFocus{
	font-family: 'Balboa';
	font-size: 3.5vh;
	color: #FBA00B;
}
.myInputFocus input{
	font-family: "Balboa";
	/* margin-top: 5px; */
	background-color: black;
	font-size: 3.5vh;
	border-radius: 5px;
	border: 1px solid #FBA00B;
	color:#FFF;
	padding:10px;	
}
.MyButtonGO{
	margin-left: 20px;
	margin-right: 20px;
	border-width: 1px;
	padding: 10px;
	border-radius: 5px;
	border-color: #FBA00B;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.719);
	color: #FFF;
	font-family: "Balboa";
	font-size: 4vh;
}

.MyButtonGOFocus{
	margin-left: 20px;
	margin-right: 20px;
	border-width: 1px;
	padding: 10px;
	border-radius: 5px;
	border-color: #FBA00B;
	text-align: center;
	font-family: "Balboa";
	font-size: 4vh;

	color:#fff;
	background-color:#FBA00B;
}
